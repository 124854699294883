<script>
import { required, email } from "vuelidate/lib/validators";
import AuthenticationCode from "@/components/authentication-code";
import {customerService} from "@/helpers/fakebackend/customer.service";
import {requestMessage} from "@/helpers/request-message";
import Swal from "sweetalert2";
import {userService} from "@/helpers/fakebackend/user.service";
export default {
  components: {AuthenticationCode},
  data() {
    return {
      email: "",
      submitted: false,
      regError: "",
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      step: 1,
      password: "",
      confirmPassword: "",
      code: "",
      token: "",
    };
  },
  validations: {
    email: { required, email },
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.autocompleteCode();
  },
  methods: {
    autocompleteCode(){
      const params = this.paramsToJson(window.location.href);
      if (Object.keys(params).length > 1) {
        if(params.otccode && params.email){
          const paramCode = `${parseInt(params.otccode)}`;
          if (!isNaN(parseInt(paramCode)) &&  paramCode.length === 6){
            this.code = paramCode;
            this.email = params.email;
            this.step = 2;
            this.verifyAuthenticationcode();
          }
        }
      }
    },
    paramsToJson(url){
      const params = unescape(url).split("?")[1]?.split("&");
      let objectParams = {};
      params?.forEach((param)=>{
        const _param = param.split("=");
        objectParams[_param[0]] = _param[1];
      });
      return objectParams;
    },
    updateCode(code){
      this.code = code;
      if (code.length === 6)
        this.verifyAuthenticationcode();
    },
    clearError(){
      this.isRegisterError = false;
      this.regError = "";
    },
    sendAuthenticationcode() {
      this.submitted = true;
      this.isRegisterError = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitted = false;
        return;
      }else {
        const self = this;
        customerService.sendAuthenticationcode(this.email).then(()=>{
          this.submitted = false;
          this.clearError();
          self.step ++;
        }).catch((data)=>{
          this.submitted = false;
          this.isRegisterError = true;
          this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
        });
      }
    },
    resendAuthenticationcode() {
      this.submitted = true;
      this.isRegisterError = false;
      customerService.resendAuthenticationcode(this.email).then((data)=>{
        this.submitted = false;
        this.clearError();
        Swal.fire(requestMessage[data.code].de, "", "success")
      }).catch((data)=>{
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      });
    },
    verifyAuthenticationcode(){
      this.submitted = true;
      if (this.code.length !== 6){
        this.isRegisterError = true;
        this.regError = "Geben Sie einen gültigen Code ein";
        this.submitted = false;
        return;
      }
      const self = this;
      customerService.verifyAuthenticationcode(this.code, this.email).then((data)=>{
        this.submitted = false;
        this.clearError();
        self.step ++;
        this.token = data.content.auth_token;
      }).catch((data)=>{
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      });
    },
    setPassword(){
      this.submitted = true;
      if (this.password === "" && this.password === this.confirmPassword){
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = "Geben Sie bitte ein Passwort ein";
        return;
      }
      if (this.password !== this.confirmPassword){
        this.isRegisterError = true;
        this.submitted = false;
        this.regError = "Die beiden Passworte müssen gleich sein";
        return;
      }
      customerService.setPassword(this.password, this.confirmPassword, this.token).then((data)=>{
        this.submitted = false;
        this.clearError();
        this.authentication(data.content);
      }).catch((data)=>{
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      });
    },
    authentication(user){
      userService.login(user).then(data=>{
        if(data) {
          this.$router.push({name: "home"});
        }else{
          this.isRegisterError = true;
          this.regError = "Ein Fehler ist während der Authentifizierung aufgetreten. Gehen Sie auf die Login-Seite, um sich einzuloggen";
        }
      });
    },
    runStepAction(){
      switch (this.step){
        case 1:
          this.sendAuthenticationcode();
          break;
        case 2:
          this.verifyAuthenticationcode();
          break;
        case 3:
          this.setPassword();
          break;
      }
    },
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content d-flex min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">

                  <div v-if="!registerSuccess" class="col-lg-9">
                    <div>
                      <div>
                        <a href="/" class="logo">
                          <img src="@/assets/images/logo-dark.svg" height="80" alt="logo" />
                        </a>
                      </div>

                      <div class="p-2 mt-5">
                        <h4 class="font-size-18 mb-4 font-weight-bold">Schnelle Registrierung</h4>

                        <b-alert
                            v-model="isRegisterError"
                            class="mt-3"
                            variant="danger"
                            dismissible
                        >{{regError}}</b-alert>

                        <b-overlay :show="submitted">
                          <form class="form-horizontal" v-on:submit="(e)=>e.preventDefault()">

                            <div class="mb-4" v-if="step===1">
                              <input
                                  v-model="email"
                                  type="email"
                                  class="form-control"
                                  id="useremail"
                                  placeholder="Email"
                                  :class="{ 'is-invalid': !submitted && $v.email.$error }"
                              />
                              <div v-if="!submitted && $v.email.$error" class="invalid-feedback">
                                <span v-if="!$v.email.required">Die Email-Adresse ist notwendig.</span>
                                <span v-if="!$v.email.email">Geben Sie bitte eine gültige Email-Adresse an.</span>
                              </div>
                              <p class="mt-4">
                                <small>
                                  Mit der Bestätigung des Formulars stimme ich den <a href="" class="underline-primary"><b>Allgemeinen Geschäftsbedingungen</b></a>
                                  , der <a href="" class="underline-primary"><b>Leistungsbeschreibung</b></a>, sowie der
                                  <a href="" class="underline-primary"><b>Datenschutzerklärung</b></a> zu.
                                </small>
                              </p>
                            </div>

                            <div class="form-group auth-form-group-custom mb-4" v-if="step===2">
                              <h4 class="font-size-18 mt-4 font-weight-bold">CODE EINFÜGEN</h4>
                              <p>Ein sechsstelliger Code wurde an Ihre Email-Adresse gesendet.</p>
                              <authentication-code :digit="6" :value="code" v-on:code-change="updateCode"></authentication-code>
                              <div class="mt-2">Haben den Code nicht bekommen ? <a href="" class="underline-primary" @click="(e) => { e.preventDefault(); resendAuthenticationcode()}">Code erneut senden</a></div>
                            </div>

                            <div  v-if="step===3">
                              <div class="form-group auth-form-group-custom mb-4">
                                <div class="password-container">
                                  <input
                                      v-model="password"
                                      type="password"
                                      class="form-control"
                                      id="password"
                                      placeholder="Passwort"
                                  />
                                  <span class="password-btn"><i class="fa fa-eye"></i></span>
                                </div>
                              </div>
                              <div class="form-group auth-form-group-custom mb-4">
                                <div class="password-container">
                                  <input
                                      v-model="confirmPassword"
                                      type="password"
                                      class="form-control"
                                      id="confirmPassword"
                                      placeholder="Bestätigung des Passwortes"
                                  />
                                  <span class="password-btn"><i class="fa fa-eye"></i></span>
                                </div>
                              </div>
                            </div>

                            <div>
                              <button
                                  class="btn btn-primary w-md waves-effect waves-light"
                                  type="button" @click="runStepAction"
                              ><span v-if="step === 1">Los geht's</span> <span v-if="step === 2">Weiter</span> <span v-if="step > 2">Fertig</span></button>
                            </div>

<!--                            <div v-if="step === 1">
                              <div class="mt-4">
                                <button
                                    class="btn btn-outline btn-outline-primary btn-block no-border-radius pt-3 pb-3"
                                    type="submit"
                                ><i class="mdi mdi-google"></i> Sign Up with Google</button>
                              </div>
                              <div class="mt-3">
                                <button
                                    class="btn btn-outline btn-outline-primary btn-block no-border-radius pt-3 pb-3"
                                    type="submit"
                                ><i class="mdi mdi-github"></i> Sign Up with GitHub</button>
                              </div>
                            </div>-->

                          </form>
                        </b-overlay>
                      </div>
                    </div>
                  </div>

                  <div v-if="registerSuccess" class="col-lg-9">
                    <div>
                      <div>
                        <a href="/" class="logo">
                          <img src="@/assets/images/logo-dark.svg" height="80" alt="logo" />
                        </a>
                      </div>
                    </div>
                    <b-alert
                        v-model="registerSuccess"
                        class="mt-3"
                        variant="success">
                      Die Registrierung wurde erfolgreich abgeschloßen. Sie werden jetzt in Ihr Dashboard weitergeleitet.
                    </b-alert>
                    <div>
                      <router-link
                          tag="a"
                          to="/login"
                          class="font-weight-medium text-primary"
                      >Zurück zum Einloggen</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg ml-auto">
              <div class="p-4 text-white">
                <h1 class="text-primary ml-4">Jetzt Starten –</h1>
                <h1 class="text-white ml-4">in 4 einfachen Schritten</h1>
                <div class="underline-primary  ml-4 mt-4 mb-4" style="width: 100px"></div>
                <div class="content">
                  <div class="row">
                    <div class="col-2 text-right"><img src="@/assets/images/icons/envelope.png" alt="enveloppe" style="width:  100px"></div>
                    <div class="col-8">
                      <div class="d-flex align-items-center">
                        <h4><span class="bg-primary text-dark badge p-2 rounded-circle mr-2"><b>1.</b></span></h4>
                        <h5>E-Mail-Adresse eingeben und Code per E-Mail erhalten oder Sign up nutzen</h5>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-2 text-right"><img src="@/assets/images/icons/verification.png" alt="verification" style="width: 80px"></div>
                    <div class="col-8">
                      <div class="d-flex align-items-center">
                        <h4><span class="bg-primary text-dark badge p-2 rounded-circle mr-2"><b>2.</b></span></h4>
                        <h5>Den Verifikations-Code eingeben</h5>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2 text-right"><img src="@/assets/images/icons/password.png" alt="Password" style="width:  80px"></div>
                    <div class="col-8">
                      <div class="d-flex align-items-center">
                        <h4><span class="bg-primary text-dark badge p-2 rounded-circle mr-2"><b>3.</b></span></h4>
                        <h5>Persönliches Passwort vergeben</h5>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row mt-4">
                        <div class="col-4"></div>
                        <div class="col-7">
                          <div class="d-flex">
                            <h4><span class="bg-primary text-dark badge p-2 rounded-circle mr-2"><b>4.</b></span></h4>
                            <h5><b class="text-primary">kostenlos umschauen oder Profil vervollständigen</b> und die volle Power von kyberio nutzen</h5>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-2"></div>
                        <div class="col-10">
                          <div class="underline-primary mb-3" style="width:50px"></div>
                          <p>
                            Du kannst dich bei uns kostenlos umschauen, ganz ohne weitere
                            Dateneingabe, sobald du die Zahlungsart hinterlegt hast,
                            kannst du die volle Power von kyberio nutzen und erhältst sogar
                            <b class="text-primary">100€ Guthaben</b> für die ersten 30 Tage.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <img src="@/assets/images/icons/team.png" alt="Team">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
